<template>
  <v-dialog v-model="dialog" scrollable max-width="800px">
    <template #activator="{ on: dialog }">
      <v-tooltip top>
        <template #activator="{ on: tooltip }">
          <span v-on="{ ...tooltip }">
            <v-btn
              fab
              top
              left
              color="secondary"
              class="ma-2 white--text"
              v-if="!editMode"
              :disabled="disabled"
              @click="onShowDialog"
              v-on="{ ...dialog }"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-icon
              v-else
              class="mx-1"
              color="primary"
              small
              v-on="{ ...dialog }"
              @click="onShowUpdate"
              :disabled="disabled"
              v-show="!itemData.edited"
            >
              mdi-pencil
            </v-icon>
          </span>
        </template>
        <span>{{ disabled ? "Update is no longer available" : editMode ? "Edit TestPoint" : "Add New TestPoint" }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text"
          >{{ editMode ? "Edit" : "New" }} TestPoint</span
        ><v-spacer /><span
          v-if="override"
          class="headline font-weight-bold red--text text--darken-1"
          >Override Validation Controls is enabled</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <small class="red--text">* indicates required field</small>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Reference Designator *"
                  name="ref"
                  type="text"
                  autocomplete="off"
                  autofocus
                  v-model.lazy="f_formData.ref"
                  :rules="rules.requireCheck"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Net *"
                  name="net"
                  type="text"
                  autocomplete="off"
                  v-model.lazy="f_formData.net"
                  :rules="rules.requireCheck"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-if="!enabledViewMils"
                  label="X-Coordinate (mm) *"
                  name="xCoordinate"
                  type="number"
                  v-model.number="f_formData.x"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeMinSpacing('x')"
                />
                <v-text-field
                  v-else
                  label="X-Coordinate (mils) *"
                  name="xCoordinateMils"
                  type="number"
                  v-model.number="f_formData.x_mils"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeMinSpacingMils('x')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-if="!enabledViewMils"
                  label="Y-Coordinate (mm) *"
                  name="yCoordinate"
                  type="number"
                  v-model.number="f_formData.y"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeMinSpacing('y')"
                />
                <v-text-field
                  v-else
                  label="Y-Coordinate (mils) *"
                  name="yCoordinateMils"
                  type="number"
                  v-model.number="f_formData.y_mils"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeMinSpacingMils('y')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-if="!enabledViewMils"
                  label="Clearance (mm) *"
                  name="min_spacing"
                  type="number"
                  readonly
                  v-model.number="f_formData.min_spacing"
                />
                <v-text-field
                  v-else
                  label="Clearance (mils) *"
                  name="min_spacing_mils"
                  type="number"
                  readonly
                  v-model.number="f_formData.min_spacing_mils"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-select
                  :items="testpointLayers"
                  label="Layer *"
                  item-text="description"
                  item-value="pk"
                  v-model.lazy="f_formData.layer"
                  :rules="rules.requireCheck"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :items="testpointTypes"
                  label="Type *"
                  item-text="description"
                  item-value="pk"
                  v-model.lazy="f_formData.type"
                  :rules="rules.requireCheck"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :items="yesNo"
                  label="Enabled *"
                  item-text="text"
                  item-value="value"
                  v-model.lazy="f_formData.enabled"
                  :rules="rules.notNull"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="receptacleList"
                  label="Receptacle *"
                  item-text="description"
                  item-value="pk"
                  v-model="f_formData.receptacle"
                  :rules="rules.requireCheck"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="probeList"
                  label="Test Probe *"
                  item-text="description"
                  item-value="pk"
                  v-model="f_formData.probe"
                  :rules="rules.requireCheck"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider />
        <v-row class="ma-2">
          <v-btn color="warning" @click="onClose">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSave">Save</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TestPointForm",
  props: {
    editMode: Boolean,
    itemData: Object,
    override: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    enabledViewMils: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      f_formData: {},
      dialog: false,
      yesNo: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
      rules: {
        requireCheck: [(v) => !!v || this.override || "Input is required"],
        notNull: [(v) => v != null || this.override || "Input is required"],
        requirePlus: [
          (v) =>
            (!!v && v >= 0) ||
            this.override ||
            "Value should be equal or higher than 0",
        ],
      },
      MILS_TO_MM: 0.0254,
    };
  },
  computed: {
    ...mapGetters({
      testPoints: "testpoints/testPoints",
      receptacles: "dutconfigs/receptacles",
      probes: "dutconfigs/probes",
      testpointLayers: "dutconfigs/testpointLayers",
      testpointTypes: "dutconfigs/testpointTypes",
      dutConfigs: "dutconfigs/dutConfigs",
      connectionType: "fixturedesign/connectionType",
      tipStyle: "fixturedesign/tipStyle",
    }),
    receptacleList() {
      const rList = this.override
        ? this.receptacles
        : this.receptacles.filter((element) => {
            return (
              element.connection_type === this.connectionType &&
              element.pitch <= this.f_formData.min_spacing
            );
          });
      return [...rList].sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
    },
    probeList() {
      const pList = this.override
        ? this.probes
        : this.probes.filter((element) => {
            return (
              element.tip_style === this.tipStyle &&
              element.receptacles.includes(this.f_formData.receptacle)
            );
          });
      return [...pList].sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
    },
  },
  methods: {
    onShowUpdate() {
      this.f_formData = {
        pk: this.itemData.pk,
        project: this.itemData.project,
        ref: this.itemData.ref,
        name: this.itemData.name,
        net: this.itemData.net,
        x: this.itemData.x,
        y: this.itemData.y,
        min_spacing: this.itemData.min_spacing,
        x_mils: this.itemData.x_mils,
        y_mils: this.itemData.y_mils,
        min_spacing_mils: this.itemData.min_spacing_mils,
        layer: this.itemData.layerPk,
        type: this.itemData.typePk,
        receptacle: this.itemData.receptaclePk,
        probe: this.itemData.probePk,
        enabled: this.itemData.enabled,
      };
    },
    onShowDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.f_formData = {
        project: +this.$route.params.id,
        dut_config: this.dutConfigs.pk,
        ref: null,
        name: null,
        net: null,
        x: 0.0000,
        y: 0.0000,
        min_spacing: 0.0000,
        x_mils: 0.0000,
        y_mils: 0.0000,
        min_spacing_mils: 0.0000,
        layer: null,
        type: null,
        receptacle: null,
        probe: null,
        enabled: true,
      };
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      console.log(this.f_formData);
      const actionPayload = {
        editMode: this.editMode,
        data: { ...this.f_formData, name: this.f_formData.ref },
      };
      this.$emit("saveData", actionPayload);
      this.dialog = false;
    },
    computeMinSpacing(coord) {
      if (this.testPoints.length == 0) {
        this.f_formData.min_spacing = 1;
        return
      }
      let test_points = [...this.testPoints];
      if (this.editMode) {
        test_points = this.testPoints.filter(
          (element) => element.pk != this.f_formData.pk
        );
      }
      let min_spacing = null;
      test_points.forEach((testpoint) => {
        const x = testpoint.x - this.f_formData.x;
        const y = testpoint.y - this.f_formData.y;
        const ms = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        if (!min_spacing || (ms < min_spacing && ms != 0)) {
          min_spacing = ms.toFixed(4);
        }
      });
      this.f_formData.min_spacing = min_spacing;
      this.f_formData[`min_spacing_mils`] = this.f_formData[`min_spacing`] * (1 / this.MILS_TO_MM);
      this.f_formData[`${coord}_mils`] = this.f_formData[coord] * (1 / this.MILS_TO_MM);
      this.f_formData[coord] = this.f_formData[coord].toFixed(4) ;
    },
    computeMinSpacingMils(coord) {
      if (this.testPoints.length == 0) {
        this.f_formData.min_spacing_mils = 1;
        return
      }
      let test_points = [...this.testPoints];
      if (this.editMode) {
        test_points = this.testPoints.filter(
          (element) => element.pk != this.f_formData.pk
        );
      }
      let min_spacing_mils = null;
      test_points.forEach((testpoint) => {
        const x = testpoint.x_mils - this.f_formData.x_mils;
        const y = testpoint.y_mils - this.f_formData.y_mils;
        const ms = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        if (!min_spacing_mils || (ms < min_spacing_mils && ms != 0)) {
          min_spacing_mils = ms.toFixed(4);
        }
      });
      this.f_formData.min_spacing_mils = min_spacing_mils;
      this.f_formData[`min_spacing`] = this.f_formData[`min_spacing_mils`] * this.MILS_TO_MM;
      this.f_formData[coord] = this.f_formData[`${coord}_mils`] * this.MILS_TO_MM;
      this.f_formData[`${coord}_mils`] = this.f_formData[`${coord}_mils`].toFixed(4)
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
};
</script>
